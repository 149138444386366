import React, { useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { Menu } from "antd";
import { LOGIN, REGISTRATION } from "settings/constant";
import { useTranslation } from "react-i18next";
import { FaUser } from "react-icons/fa";
import useOnClickOutside from "../../../library/hooks/useOnClickOutside";

const AuthMenu = ({ showRegister }) => {
  const [state, setState] = useState(false);
  const { t } = useTranslation();
  const handleDropdown = () => {
    setState(!state);
  };
  const dropdownRef = useRef(null);
  useOnClickOutside(dropdownRef, () => setState(false));

  return (
    <div className="avatar-dropdown" ref={dropdownRef}>
      <div className="dropdown-handler" onClick={handleDropdown}>
        <FaUser />
      </div>
      <Menu className={`dropdown-menu ${state ? "active" : "hide"}`}>
        <Menu.Item key="0">
          <NavLink to={LOGIN}>{t("signIn")}</NavLink>
        </Menu.Item>
        {showRegister && (
          <Menu.Item key="1">
            <NavLink to={REGISTRATION}>{t("signUp")}</NavLink>
          </Menu.Item>
        )}
      </Menu>
    </div>
  );
};

export default AuthMenu;
