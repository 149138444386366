import React from "react";
import { LogoWrapper } from "./Logo.style";

const Logo = ({ logo, width, maxWidth }) => (
  <LogoWrapper width={width} maxWidth={maxWidth}>
    {logo && (
      <a href="/">
        <img src={logo} />
      </a>
    )}
  </LogoWrapper>
);

export default Logo;
