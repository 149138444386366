import React from "react";
import { NavLink } from "react-router-dom";
import { Menu } from "antd";

import {
  ABOUT_US,
  CAR_PARK_LIST,
  HOME_PAGE,
  NEWS,
  SERVICE,
} from "settings/constant";
import { useTranslation } from "react-i18next";

const MainMenu = ({
  className,
  showCarpark,
  showService,
  showNews,
  showAbout,
}) => {
  const { t } = useTranslation();

  return (
    <Menu className={className}>
      <Menu.Item key="0">
        <NavLink exact to={HOME_PAGE}>
          {t("navbar.home")}
        </NavLink>
      </Menu.Item>
      {showCarpark && (
        <Menu.Item key="1">
          <NavLink to={`${CAR_PARK_LIST}`}>{t("navbar.listing")}</NavLink>
        </Menu.Item>
      )}
      {showService && (
        <Menu.Item key="2">
          <NavLink exact to={`${SERVICE}`}>
            {t("navbar.service")}
          </NavLink>
        </Menu.Item>
      )}
      {showNews && (
        <Menu.Item key="3">
          <NavLink exact to={`${NEWS}`}>
            {t("navbar.news")}
          </NavLink>
        </Menu.Item>
      )}
      {showAbout && (
        <Menu.Item key="4">
          <NavLink exact to={`${ABOUT_US}`}>
            {t("navbar.about")}
          </NavLink>
        </Menu.Item>
      )}
    </Menu>
  );
};

export default MainMenu;
