import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import { Menu } from "antd";
import {
  ABOUT_US,
  ACCOUNT_SETTINGS,
  CAR_PARK_LIST,
  HOME_PAGE,
  NEWS,
  SERVICE,
} from "settings/constant";
import Cookies from "universal-cookie";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { FaGlobeAsia } from "react-icons/fa";
import { LangSwitcher } from "./Header.style";

const cookies = new Cookies();

const MobileMenu = ({
  className,
  showCarpark,
  showService,
  showNews,
  showAbout,
  onClose,
}) => {
  const token = cookies.get("token");
  const history = useHistory();
  const { t, i18n } = useTranslation();
  return (
    <Menu className={className}>
      <Menu.Item key="0">
        <NavLink exact to={HOME_PAGE} onClick={() => onClose()}>
          {t("navbar.home")}
        </NavLink>
      </Menu.Item>
      {showCarpark && (
        <Menu.Item key="1">
          <NavLink to={CAR_PARK_LIST} onClick={() => onClose()}>
            {t("navbar.listing")}
          </NavLink>
        </Menu.Item>
      )}
      {showService && (
        <Menu.Item key="2">
          <NavLink exact to={SERVICE} onClick={() => onClose()}>
            {t("navbar.service")}
          </NavLink>
        </Menu.Item>
      )}
      {showNews && (
        <Menu.Item key="3">
          <NavLink exact to={NEWS} onClick={() => onClose()}>
            {t("navbar.news")}
          </NavLink>
        </Menu.Item>
      )}
      {showAbout && (
        <Menu.Item key="4">
          <NavLink exact to={ABOUT_US} onClick={() => onClose()}>
            {t("navbar.about")}
          </NavLink>
        </Menu.Item>
      )}
      {token && (
        <Menu.Item key="5">
          <a
            onClick={() => {
              cookies.set("token", "");
              history.push("/");
              onClose();
            }}
          >
            {t("logout")}
          </a>
        </Menu.Item>
      )}
      <Menu.Item key="6">
        <LangSwitcher
          onClick={() => {
            const lang = i18n.language === "zh" ? "en" : "zh";
            i18next.changeLanguage(lang);
            cookies.set("lang", lang);
            onClose();
          }}
        >
          <FaGlobeAsia />
          {i18n.language === "zh" ? "EN" : "中"}
        </LangSwitcher>
      </Menu.Item>
    </Menu>
  );
};

export default MobileMenu;
