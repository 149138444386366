import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import { Button, Radio } from "antd";
import defaultTheme from "../../themes/default.theme";

export const FilterButtonItem = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;

  > div {
    @media only screen and (max-width: 820px) {
      font-size: 13px;
    }
  }
`;

export const BasicButton = styled.button`
  margin-right: 8px;
  padding: 5px;
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
`;

export const RadioGroup = styled(Radio.Group)`
  & .ant-radio-button-wrapper {
    margin: 5px;
    padding: 20px 5px;
    width: 100px;
    height: 100px;
  }

  & .ant-radio-button-wrapper:hover {
    background: ${defaultTheme.button.red} !important;
    border-color: ${defaultTheme.button.red} !important;
  }

  & .ant-radio-button.ant-radio-button-checked,
  .ant-radio-button-wrapper.ant-radio-button-wrapper-checked,
  .ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background: ${defaultTheme.button.red} !important;
    border-color: ${defaultTheme.button.red} !important;
  }
`;

export const RadioButton = styled(Radio.Button)`
  background: ${({ isSelected }) =>
    isSelected ? defaultTheme.button.red : "#fff"};
  color: ${({ isSelected }) => (isSelected ? "#fff" : "#000")};

  &:hover {
    color: ${({ isSelected }) => (isSelected ? "#fff" : "#000")};
  }

  & img {
    margin-bottom: 9px;
  }

  & div {
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 1rem;
    text-align: center;
  }
`;

export const SearchWrapper = styled.div`
  width: calc(100% - 60px);
  padding: 28px 30px 30px;
  border-radius: 6px;
  background-color: ${themeGet("color.1", "#ffffff")};
  box-shadow: 0 1px 20px rgba(0, 0, 0, 0.08);
  position: absolute;
  bottom: max(20%, 80px);
  z-index: 2;

  @media only screen and (min-width: 569px) and (max-width: 991px) {
    width: calc(100% - 60px);
    left: 30px;
  }

  @media only screen and (max-width: 480px) {
    width: calc(100% - 30px);
    padding: 15px;
    left: 15px;
  }
`;

export const FormWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${themeGet("color.2", "#F7F7F7")};
  border-radius: 3px 0 0 3px;
  min-height: 60px;

  @media only screen and (max-width: 991px) {
    flex-direction: ${({ isListPage }) => !isListPage && "column"};
  }

  @media only screen and (max-width: 700px) {
    flex-direction: ${({ isListPage }) => isListPage && "column"};
  }

  @media only screen and (max-width: 480px) {
    margin-top: 10px;
  }

  /* submit button style */
  button[type="submit"].ant-btn {
    min-width: 157px;
    height: 60px;
    color: ${themeGet("color.1", "#ffffff")};
    background-color: ${themeGet("primary.0", "#008489")};
    border-radius: 0 3px 3px 0;
    border: 0;
    box-shadow: none;
    font-size: 15px;
    font-weight: 700;
    text-shadow: none;

    @media only screen and (max-width: 991px) {
      min-width: 100%;
      border-radius: 0 0 3px 3px;
    }

    @media only screen and (max-width: 480px) {
      height: 47px;
    }

    &::after {
      display: none;
    }

    &:hover {
      opacity: 0.85;
    }
  }
`;

export const ComponentWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 20px;
  width: ${({ width }) => width || "27%"};

  @media only screen and (max-width: 991px) {
    margin: 4px auto;
    width: ${({ width }) => width || "70%"};
  }

  @media only screen and (max-width: 700px) {
    width: 90%;
  }

  @media only screen and (max-width: 480px) {
    padding-left: 25px;
    width: 95%;
  }

  .target {
    right: 10px;
    left: auto;

    @media only screen and (max-width: 480px) {
      right: 25px;
    }
  }

  .bookingType-selector {
    .ant-select-dropdown {
      z-index: 10005 !important;
    }

    .ant-select-selector {
      background-color: transparent !important;
      border: none !important;
    }

    width: 100%;

    div {
      margin-bottom: 0px !important;
    }
  }

  .booking-type-icon {
    position: absolute;
    left: 10px;
    fill: ${themeGet("primary.0", "#008489")};
    color: ${themeGet("primary.0", "#008489")};
  }

  .location-selector {
    width: 100%;

    .ant-select-selector {
      background-color: transparent !important;
      border: none !important;
      width: 100%;
    }

    div {
      margin-bottom: 0px !important;
    }
  }

  /* date picker style */

  .ant-picker {
    padding: 0;
    border: none !important;
    background: none;
  }

  .ant-picker-input input {
    color: #000 !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    padding-right: 5px !important;
    text-overflow: initial !important;
  }

  .ant-calendar-picker-input.ant-input {
    color: red !important;
  }

  .ant-picker.ant-picker-range {
    background: none !important;
    border: none !important;
  }

  .ant-picker-focused {
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .ant-picker-range .ant-picker-active-bar {
    background: red !important;
  }

  .date_picker {
    width: 100%;
    margin: 0 0 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: 991px) {
      justify-content: flex-start;
      height: 60px;
    }

    @media only screen and (max-width: 480px) {
      height: 47px;
    }

    .DayPicker__withBorder {
      box-shadow: 0 15px 46px -10px rgba(26, 26, 29, 0.3);
    }

    .DateRangePicker {
      display: block;
      width: 100%;

      .DateRangePickerInput {
        border: 0;
        padding-right: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: transparent;

        .DateInput {
          width: 100%;
          padding: 0 15px 0 30px;
          background: transparent;

          @media only screen and (min-width: 569px) and (max-width: 991px) {
            padding: 0 25px 0 30px;
          }

          @media only screen and (max-width: 568px) {
            padding: 0 15px 0 25px;
          }

          .DateInput_fang {
            display: none;
          }

          .DateInput_input {
            padding: 0;
            font-weight: 400;
            color: ${themeGet("text.0", "#2C2C2C")};
            background: transparent;

            &.DateInput_input__focused {
              border-color: transparent;
            }

            &::placeholder {
              color: ${themeGet("text.0", "#2C2C2C")};
            }
          }
        }

        .DateRangePickerInput_arrow {
          & + .DateInput {
            input {
              text-align: right;
            }
          }
        }
      }

      .DateRangePicker_picker {
        margin-top: -12px;
        z-index: 2;

        @media only screen and (max-width: 991px) {
          top: 47px !important;
        }

        @media only screen and (max-width: 320px) {
          left: -29px !important;
          .DayPicker,
          .DayPicker > div > div,
          .DayPicker > div > div .DayPicker_transitionContainer {
            width: 294px !important;
          }

          .DayPicker {
            .DayPicker_weekHeader {
              padding: 0 !important;
            }

            .CalendarMonth {
              padding: 0 !important;
            }
          }
        }
      }
    }
  }

  /* icon style */

  > svg {
    position: absolute;
    z-index: 1;
    top: auto;

    &.map-marker,
    &.calendar {
      left: 10px;
      fill: ${themeGet("primary.0", "#008489")};
    }

    &.calendar {
      @media only screen and (max-width: 480px) {
        width: 14px;
        height: 14px;
      }
    }

    &.caret-down {
      right: 12px;
      left: auto;
      fill: ${themeGet("text.1", "#909090")};

      @media only screen and (max-width: 991px) {
        right: 20px;
      }

      @media only screen and (max-width: 991px) {
        right: 15px;
      }
    }
  }
`;
