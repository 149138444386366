import React from "react";
import { withRouter } from "react-router-dom";
import Search from "../../../components/Search/Search";

const NavbarSearch = () => (
  <div style={{ marginLeft: 15, marginRight: 15, width: "100%" }}>
    <Search isListPage />
  </div>
);

export default withRouter(NavbarSearch);
