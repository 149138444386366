import {
  BasicButton,
  ComponentWrapper,
  FormWrapper,
  SearchWrapper,
} from "./Search.style";
import React, { useContext } from "react";
import { Button, DatePicker } from "antd";
import Select from "components/UI/Select/Select";
import { FaMapMarkerAlt, FaSearch } from "react-icons/fa";
import SearchViewModel from "./viewModel";
import en from "antd/es/date-picker/locale/en_GB";
import zh from "antd/es/date-picker/locale/zh_TW";
import "moment/locale/zh-tw";
import "../../container/CarparkDetail/containers/PackageOptions/PackageOptions.css";
import "../../assets/style/style_overrides.css";
import FormControl from "../UI/FormControl/FormControl";

const selectStyle = {
  control: (base) => ({
    ...base,
    border: "none",
    boxShadow: "none",
    background: "transparent",
    width: "100%",
  }),
};

const SearchForm = ({ isListPage = false }) => {
  const {
    t,
    i18n,
    bookingTypes,
    renderBookingTypeIcon,
    regions,
    Controller,
    control,
    handleSubmit,
    setValue,
    bookingTypeValue,
    startDateValue,
    endDateValue,
    regionValue,
    onSubmitSearch,
    setDefaultMonthlyStart,
    setDefaultMonthlyEnd,
    setDefaultHourlyStart,
    setDefaultHourlyEnd,
    onChangeStart,
    disabledStartDate,
    disabledEndDate,
  } = useContext(SearchViewModel.Context);

  const renderBookingTypeSelector = () => (
    <ComponentWrapper
      width={isListPage && "170px"}
      className="booking-type-react-select"
    >
      {renderBookingTypeIcon()}
      <div className="bookingType-selector">
        <FormControl>
          <Controller
            as={
              <Select
                defaultValue={bookingTypes[0]}
                options={bookingTypes}
                style={selectStyle}
                value={bookingTypeValue}
              />
            }
            id="bookingType"
            name="bookingType"
            control={control}
          />
        </FormControl>
      </div>
    </ComponentWrapper>
  );

  const renderAntDatePicker = () => {
    const showTime =
      bookingTypeValue === "Hourly" ? { format: "HH:mm" } : false;
    const format =
      bookingTypeValue === "Monthly" ? "YYYY-MM-DD" : "YYYY-MM-DD HH:mm";
    const locale = i18n.language === "en" ? en : zh;
    const style = { border: "1px solid #d9d9d9" };

    const onClickStart =
      bookingTypeValue === "Hourly"
        ? setDefaultHourlyStart
        : setDefaultMonthlyStart;
    const onClickEnd =
      bookingTypeValue === "Hourly"
        ? setDefaultHourlyEnd
        : setDefaultMonthlyEnd;

    return (
      <ComponentWrapper
        className={isListPage ? "list-page-date-picker" : ""}
        width={isListPage && "70%"}
      >
        <DatePicker
          inputReadOnly
          showTime={showTime}
          format={format}
          locale={locale}
          onClick={onClickStart}
          onChange={onChangeStart}
          style={style}
          value={startDateValue}
          placeholder={t("listing.startDate")}
          suffixIcon={null}
          allowClear={false}
          showToday={false}
          disabledDate={disabledStartDate}
          minuteStep={15}
          onOk={(start) => setValue("start", start)}
          inputReadOnly
        />
        <DatePicker
          inputReadOnly
          showTime={showTime}
          format={format}
          locale={locale}
          onClick={onClickEnd}
          onChange={(end) => setValue("end", end)}
          style={style}
          value={endDateValue}
          placeholder={t("listing.endDate")}
          suffixIcon={null}
          allowClear={false}
          showToday={false}
          disabledDate={disabledEndDate}
          minuteStep={15}
          // onOk={(end) => setValue("end", end)}
          inputReadOnly
        />
      </ComponentWrapper>
    );
  };

  const renderRegionSelector = () => (
    <ComponentWrapper>
      <FaMapMarkerAlt className="map-marker" />
      <div className="location-selector">
        <FormControl>
          <Controller
            as={
              <Select
                options={regions}
                placeholder={t("listing.location")}
                style={selectStyle}
                value={regionValue}
              />
            }
            id="region"
            name="region"
            control={control}
          />
        </FormControl>
      </div>
    </ComponentWrapper>
  );

  return (
    <form
      onSubmit={handleSubmit(onSubmitSearch)}
      style={{ marginTop: 5, marginBottom: 5 }}
    >
      <FormWrapper isListPage={isListPage}>
        {renderBookingTypeSelector()}

        {bookingTypeValue !== "Walkin" && renderAntDatePicker()}

        {isListPage ? (
          <Button
            type="primary"
            htmlType="submit"
            size="small"
            className="list-page-search-btn"
          >
            <FaSearch style={{ marginRight: 5 }} />
            {t("home.searchArea.search")}
          </Button>
        ) : (
          <>
            {renderRegionSelector()}

            <Button
              type="primary"
              htmlType="submit"
              size="small"
              className="home-page-search-btn"
            >
              <FaSearch style={{ marginRight: 5 }} />
              {t("home.searchArea.search")}
            </Button>
          </>
        )}
      </FormWrapper>
    </form>
  );
};

const Search = ({ isListPage = false }) => (
  <>
    {isListPage ? (
      <SearchForm isListPage />
    ) : (
      <SearchWrapper>
        <SearchForm />
      </SearchWrapper>
    )}
  </>
);

export default SearchViewModel.withProvider(Search);
