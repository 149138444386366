import React from "react";
import { Col, Row } from "antd";
import {
  FacebookFilled,
  GoogleOutlined,
  WechatOutlined,
} from "@ant-design/icons";
import { Link, useHistory } from "react-router-dom";
import {
  FaEnvelope,
  FaGlobeAsia,
  FaPhoneAlt,
  FaRegRegistered,
  FaSearch,
  FaSignInAlt,
  FaSignOutAlt,
  FaUser,
} from "react-icons/fa";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import Cookies from "universal-cookie";
import {
  Contact,
  IconButton,
  NavLink,
  Other,
  SocialLink,
  Wrapper,
} from "./HomeNavbar.style";
import {
  ABOUT_US,
  ACCOUNT_SETTINGS,
  CAR_PARK_LIST,
  HOME_PAGE,
  LOGIN,
  NEWS,
  REGISTRATION,
  SERVICE,
} from "../../../settings/constant";
import Heading from "../../../components/UI/Heading/Heading";

const cookies = new Cookies();

const HomeNavbar = ({
  logo,
  className,
  isLogin,
  socialLink,
  phone,
  email,
  showRegister,
  showCarpark,
  showService,
  showNews,
  showAbout,
}) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const navLinks = [
    { url: HOME_PAGE, show: true, i18nKey: "home" },
    { url: CAR_PARK_LIST, show: showCarpark, i18nKey: "listing" },
    { url: SERVICE, show: showService, i18nKey: "service" },
    { url: NEWS, show: showNews, i18nKey: "news" },
    { url: ABOUT_US, show: showAbout, i18nKey: "about" },
  ].reduce((acc, { url, show, i18nKey }) => {
    if (show)
      acc.push(
        <Link to={url}>
          <Heading as="h3" content={t(`navbar.${i18nKey}`)} />
        </Link>
      );
    return acc;
  }, []);
  return (
    <Wrapper className={className}>
      <Row>
        <Col span={8} className="logo">
          <img src={logo} alt="" />
        </Col>
        <Col span={16}>
          <SocialLink>
            {socialLink.facebook && (
              <a
                href="javascript:window.open('http://www.facebook.com/sharer.php?u='+encodeURIComponent(document.location.href)+'&t='+encodeURIComponent(document.title),'_blank','toolbar=yes, location=yes, directories=no, status=no, menubar=yes, scrollbars=yes, resizable=no, copyhistory=yes, width=600, height=450,top=100,left=350');void(0)"
                aria-label="facebook"
              >
                <FacebookFilled />
                <span>{t("navbar.share", { name: "Facebook" })}</span>
              </a>
            )}
            {/* {socialLink.google && ( */}
            {/*  <a aria-label="google"> */}
            {/*    <GoogleOutlined /> */}
            {/*  </a> */}
            {/* )} */}
            {/* {socialLink.wechat && ( */}
            {/*  <a aria-label="wechat"> */}
            {/*    <WechatOutlined /> */}
            {/*  </a> */}
            {/* )} */}
          </SocialLink>
          <NavLink numOfChild={navLinks.length}>{navLinks}</NavLink>
          <Other>
            <div>
              {email && (
                <Contact style={{ marginRight: 20 }}>
                  <div>
                    <FaEnvelope />
                  </div>
                  <a href={`mailto:${email}`}>{email}</a>
                </Contact>
              )}
              {phone && (
                <Contact>
                  <div>
                    <FaPhoneAlt />
                  </div>
                  <a href={`tel:${phone}`}>{phone}</a>
                </Contact>
              )}
            </div>
            <div>
              {!isLogin && (
                <IconButton style={{ marginRight: 20 }}>
                  <Link to={LOGIN}>
                    <FaSignInAlt />
                    <span>{t("signIn")}</span>
                  </Link>
                </IconButton>
              )}
              {!isLogin && showRegister && (
                <IconButton style={{ marginRight: 20 }}>
                  <Link to={REGISTRATION}>
                    <FaUser />
                    <span>{t("signUp")}</span>
                  </Link>
                </IconButton>
              )}
              {isLogin && (
                <IconButton style={{ marginRight: 20 }}>
                  <Link to={ACCOUNT_SETTINGS}>
                    <FaUser />
                    <span>{t("navbar.account")}</span>
                  </Link>
                </IconButton>
              )}
              {isLogin && (
                <IconButton style={{ marginRight: 20 }}>
                  <a
                    onClick={() => {
                      cookies.set("token", "");
                      history.push("/");
                    }}
                  >
                    <FaSignOutAlt />
                    <span>{t("logout")}</span>
                  </a>
                </IconButton>
              )}
              <IconButton>
                <a
                  onClick={() => {
                    const lang = i18n.language === "zh" ? "en" : "zh";
                    i18next.changeLanguage(lang);
                    cookies.set("lang", lang);
                  }}
                >
                  <FaGlobeAsia />
                  <span>{i18n.language === "zh" ? "EN" : "中"}</span>
                </a>
              </IconButton>
            </div>
          </Other>
        </Col>
      </Row>
    </Wrapper>
  );
};

export default HomeNavbar;
