export default {
  learnMore: "Learn more",
  signIn: "Login",
  signUp: "Register",
  logout: "Log Out",
  noResults: "No Results",
  hkd: "HKD",
  aboutUs: {
    awards: "Awards",
  },
  faq: {
    parking: "Parkers",
    general: "General",
    otherQuestions: "Do you have any other questions?",
    contactUs: "Contact our help center",
  },
  navbar: {
    listing: "Find Car Park",
    service: "Service",
    news: "Latest News",
    about: "About Us",
    account: "My Account",
    profile: "View Profile",
    home: "Home",
    share: "Share on {{name}}",
  },
  footer: {
    reserved: "© {{year}} {{name}} All right reserved",
    version: "Version",
    helpful: "Helpful Links",
    news: "News",
    contact: "Contact Us",
    lpTac: "Let’s Park Terms and Condition",
    carpark: "Find Car Park",
    tac: "Parking Terms and Conditions",
    service: "Service",
    privacy: "Privacy Policy",
    latest: "Latest News",
    disclaimer: "Disclaimer",
    about: "About Us",
    cancellation: "Cancellation Policy",
    faq: "FAQ",
    address: "Address",
    email: "Email",
    phone: "Phone",
    fax: "Fax",
  },
  auth: {
    signIn: {
      welcome: "Welcome Back",
      pleaseLogin: "Please log into your account",
      loginWith: "Or log in with",
      notMember: "Not a member yet? ",
      signUp: "Sign up now!",
      email: "Email",
      password: "Password",
      remember: "Remember me",
      required: "This field is required!",
      invalidEmail: "Please enter a valid email address!",
      forget: "Forget Password?",
      login: "Login",
      phone: "Mobile Phone",
      invalidCredential: "Invalid email or password",
      userNotFound: "No user found!",
      notValidated: "User email not validated yet",
      disabled:
        "The account has been locked or the activation process has not been completed. An email has been sent to your email address containing an activation link.",
      remark1: "By logging in, you agree to our ",
      termsOfUse: "Terms of Use",
      remark2:
        " and to receive emails, updates and acknowledge that you have read our ",
      privacyPolicy: "Privacy Policy",
      accountDisabled:
        "Your account have been disabled, please contact admin for more details",
    },
    signUp: {
      activate: "Activate",
      correctInfo: "I agree all the information above are correct",
      correctInfoValidate:
        "Please confirm the information provided are correct",
      mustAgreeTnC:
        "You must agree the terms and conditions before activating your account",
      next: "Next",
      welcome: "Welcome",
      createAccount: "Create an account",
      haveAccount: "Already have an account?",
      signUpHere: "Login here",
      individual: "Individual",
      corporate: "Corporate",
      firstName: "First name",
      lastName: "Last name",
      company: " Company",
      jobTitle: "Job title",
      title: "Title",
      mr: "Mr.",
      mrs: "Mrs.",
      ms: "Ms.",
      email: "Email",
      emailInvalid: "Email format invaild",
      required: "Please fill in the field",
      password: "Password",
      passwordRules:
        "Your password must have eight or more characters and include upper and lowercase letters",
      passwordMatch: "Passwords must match",
      confirmPassword: "Confirm password",
      mobile: "Mobile",
      whiteSpace: ".",
      verificationCode: "Verification code",
      sendSms: "Send SMS",
      codeCountDown: "Press to send a verification code {{counter}} seconds ",
      agreeMarketing:
        "I agree to the proposed use of any personal data in direct marketing",
      register: "Register",
      facebook: "Facebook",
      gmail: "Google",
      withEmail: "Continue with Email",
      smsSent: "SMS has been sent to your mobile phone!",
      checkNetwork: "Please check your network setting.",
      emailUsed: "Email exists!",
      phoneNumberUsed: "Mobile number exists!",
      smsCodeInvalid: "Verification code invalid",
      congrats: "Congratulations!",
      accountCreated: "You have created a new account.",
      checkEmail: "Please check your email to confirm the registration.",
      toHomePage: "Go to home page",
      submitCompanyBr: "Please submit your company BR to ",
      forApproval: " for approval.",
      informViaEmail:
        "We will inform you via email once your account is approved.",
    },
    forgetPassword: {
      reset: "Reset Password",
      enterEmail:
        "Enter your email address & we will send you a link to reset your password",
      send: "Send Email",
      emailNotExist: "Email not exist",
    },
    checkMailbox: {
      check: "Check your email",
      resetLink:
        "Head over to your mailbox to get your reset link and create your brand new password",
      return: "Return to login",
    },
    resetPassword: {
      title: "Select your new password",
      remark: "You have requested to reset the password for: {{email}}",
      password: "Password",
      confirmPassword: "Confirm password",
      save: "Save new password",
      invalidFormat:
        "Your password must have eight or more characters and include upper and lowercase letters",
      notMatch: "Passwords don't match",
      error: "Reset password failed. Please try again.",
    },
    confirmChangePassword: {
      title: "Congratulations!",
      titleInfo: "Your password has been changed successfully!",
      remark:
        "For security purposes, we’ve sent an email to your account confirming this change.",
      return: "Go to homepage",
    },
    mobileLogin: {
      countryCode: "Country Code",
      mobileNumber: "Mobile Number",
      verificationCode: "Verification Code",
      pressToSend: "Press to send a verification code",
      resend: "Resend verification code after {{number}} seconds",
      send: "Send SMS",
      invalidPhone: "Mobile number invalid",
      smsHasSent: "SMS has been sent to your mobile phone.",
      checkNetwork: "Please check your network setting.",
      invalidCode: "Verification code invalid",
      invalidInput: "Invalid input",
      email: "Email",
    },
  },
  home: {
    searchArea: {
      search: "Search",
      monthly: "Monthly",
      hourly: "Hourly",
      carpark: "Carpark",
      from: "From",
      to: "To",
      anyLocations: "Any Locations",
      poweredBy: "Powered by",
    },
    news: {
      continue: "Continue reading...",
    },
    contactUs: {
      heading: "A few words about us",
      readMore: "Read more",
      title: "Contact us",
      subTitle:
        "Please use this form if you have any questions about our service content and we will get back with you very soon.",
      name: "Name",
      email: "Email address",
      subscription: "Email subscription",
      message: "Message",
      submit: "Submit",
      required: "This field is required.",
      validEmail: "Please enter a valid email address!",
      submitted: "Submitted",
    },
  },
  listing: {
    notApplicable: "n/a",
    filter: "Filter",
    result: "We have found {{total}} result(s)",
    hour: "hour",
    month: "month",
    walkin: "",
    startDate: "Start date",
    endDate: "End date",
    price: "HKD",
    location: "Any location",
    showMap: "Show Map",
    vehicle: "Car Type",
    service: "Service",
    feature: "Feature",
    direction: "Direction",
    indoor: "Indoor",
    withCover: "With Covers",
    noCover: "No Covers",
    noMatch: "No results were found",
    tryOther: "Please try different keywords or criteria.",
    residentOnly: "For residents only",
  },
  carparkDetail: {
    overview: "Overview",
    location: "Image/ Location",
    operatingTime: "24 Hours operation",
    monToFri: "Monday - Friday",
    saturday: "Saturday",
    sunAndPH: "Sunday and Public Holiday",
    monFri: "Mon to Fri",
    sat: "Sat",
    sunPH: "Sun & PH",
    closed: "Closed",
    carparkSuitable: "Car park suitable for",
    availableServices: "Available Services",
    otherDetails: "Other Details",
    headroom: "Headroom",
    restrictions: "Restrictions",
    accessType: "Access Type",
    paymentMethod: "Payment Method",
    remarks: "Remarks",
    noRestriction: "No restrictions",
    ownersOnly: "For owners in this building",
    parkers: "For owners and parkers in this building",
    visitorsOnly: "For visitors only",
    accessCard: "Access Card",
    octopusCard: "Octopus Card",
    accessTypeNo: "No access card / device required",
    mAbove: "{{height}}m Above",
    noLimit: "No Limit",
    m: "{{height}}m",
    map: "Map",
    streetView: "Street View",
    photos: "Photos",
    packageOptions: "Package Options",
    parkingRates: "Parking Rates",
    bookNow: "Book Now",
    indoor: "Indoor",
    noCovers: "No Covers",
    withCovers: "With Covers",
    ev: "EV Charge",
    perHour: "per hour",
    perMonth: "per month",
    bookingAvailable: "Booking Available",
    full: "Full",
    select: "Select",
    selected: "Selected",
    from: "From",
    minBooking: "* Minimum booking hour: {{hour}} hours",
    ph: "Public Holiday",
    packageDetails: "Package details",
    rentalFee: "Current monthly rental fee",
    nextRentalFee: "Next monthly rental fee",
    parkingFee: "Parking Fee",
    total: "Total",
    instant: "Instant booking",
    instantRemark: "You can book without waiting for approval",
    to: "To",
    selectTime: "Please select parking time",
    minBookingAlert: "Minimum booking is {{hour}} hours!",
    maxBookingAlert: "Maximum booking period is 2 months!",
    endDateAlert: "Selected end date is not allowed!",
    noResults: "No results were found",
    tryDiffKeyword: "Please try different keywords or criteria.",
    apply: "Apply",
    proceed: "Proceed",
    notice: "Notice",
    noticeRemark:
      "This parking space is for resident only, proof of residence is required to make a booking. Please read the flow of document submission presented as follows:",
    residentOnly: "For residents only",
    tncTitle: "Personal Information Collection Statement",
    tnc:
      "The personal data and other related information you provided to us will be used to facilitate the car park services (including handling / following up enquiries, and providing the further carpark information). The information you provided to us may be disclosed to: the holding, subsidiaries, associated, or affiliated of, or companies controlled by, or under common control with Sun Hung Kai Real Estate (Sales and Leasing) Agency Limited; managing agents or landlords of the car parks. Your personal data will not be kept longer than is necessary as per the requirements of the Personal Data (Privacy) Ordinance and will be disposed of properly. Any requests for access to or correction of Personal Data, or enquiries regarding to this Statement should be in writing and addressed to Sun Hung Kai Real Estate (Sales & Leasing) Agency Limited at {{email}} or by call at {{phone}}.</br></br>" +
      "In the event of any inconsistency between the English and Chinese versions, the English version shall prevail.",
    tncRemark:
      "Please read the following personal information collection statement before proceeding to the next step.",
    agreeAndProceed: "Agree and Proceed",
    reserve: "Reserve Quota",
    reserveQuotaRemark:
      "Your application is being processed. You may reserve this parking space first, our staff will contact you soon for the arrangement.",
    reserved: "Reserved",
    alreadyReserved: "Already have reserved space",
    alreadyReservedRemark:
      "You have reserved a space in this carpark already, making new reservation will be considered as abandoning the existing reservation.",
  },
  payment: {
    vehicleRegistration: "Vehicle registration",
    pleaseSelect: "Please select...",
    addVehicle: "Add vehicle",
    vehicleType: "Vehicle Type",
    registrationMark: "Vehicle registration mark",
    cancel: "Cancel",
    accessMethod: "Access method",
    addOctCard: "Add octopus card",
    octCardRemark1:
      "You are only allowed to enter and exit the designated car park with this registered Octopus Card.",
    octCardRemark2:
      "Please bring the same Octopus Card with you on the day of parking.",
    octCardRemark3:
      "Except the digit in bracket, all digits of the Octopus Card number printed on bottom right hand corner on the back of the card",
    octCard: "Octopus Card",
    paymentMethod: "Payment method",
    selectPayment: "Please select a payment method",
    fps: "Faster Payment System (FPS)",
    eleven: "7-Eleven Convenience Store",
    circleK: "Circle K Convenience Store",
    cheque: "HSBC's Cheque Deposit Machine (CQM)",
    otherPayment: "Other payment method",
    onlinePayment: "Credit card",
    useOtherCreditCard: "Use another credit card",
    enter: "Enter your details:",
    cardNumber: "Card number",
    cardHolder: "Card holder's name",
    cvc: "CVC",
    mm: "MM",
    yy: "YY",
    addCreditCard:
      "Add this credit card to your account for faster booking next time.",
    letsParkTAC: "Let's Park Terms & Conditions",
    parkingTAC: "Parking Terms & Conditions",
    privacyPolicy: "Privacy Policy",
    disclaimer: "Disclaimer",
    cancellationPolicy: "Cancellation Policy",
    readAndAgree: "I have read and accepted the ",
    and: " and ",
    agreeToPay:
      ". I also agree to pay the listed total amount. (7-11 and CQM payment are not available from 3 days before expiry date of monthly parking service.)",
    tncRemark:
      "{{company}} has authorized Let’s Park Company (Hong Kong) Limited to handle all online payments. ",
    next: "Proceed to next step",
    bestView:
      "This website is best viewed with latest version of Google Chrome and Safari.",
    cardRemoved: "Card removed successfully!",
    invalidCardInfo: "Invalid credit card info",
    selectVehicle: "Please select your vehicle",
    servicePackage: "Service package",
    servicePeriod: "Service period",
    promotionCode: "Promotion Code",
    invalidCode: "Please enter correct promotion code",
    currentRentalFee: "Current monthly rental fee",
    nextRentalFee: "Next monthly rental fee",
    discount: "Discount",
    total: "Total",
    paymentSummary: "Payment summary",
    promotionError1: "Please enter correct promotion code",
    promotionError2: "A coupon has already been applied once",
    promotionError3: "The account is not valid for this promotion code",
    promotionError4: "The vehicle is not valid for this promotion code",
    promotionError5: "The selected space is not valid for this promotion code",
    promotionError6:
      "The selected carpark is not valid for this promotion code",
    promotionError7: "This coupon code is invalid or has expired",
    promotionError8: "The code has exceeded the number of times it can be used",
    promotionError9:
      "The parking fee must be more than HK$ 0 after discount. If any question, please contact us",
    chooseCarType: "Please choose your car type",
    enterCarPlate: "Please enter your car plate",
    enterOctCardNumber: "Please enter your Octopus Card number",
    numberOnly: "Invalid Octopus Card Serial Number (numbers only)",
    correctFormat:
      "Please enter all digits of the Octopus Card number, except the digit in bracket",
    selectOctCard: "Please select your Octopus Card",
    enterName: "Please enter card holder's name",
    invalidCreditCardNumber: "Credit card number format invalid",
    invalidCVC: "CVC format invalid",
    invalidMonth: "Month format invalid",
    invalidYear: "Year format invalid",
    wrongCardInfo: "Incorrect card information, please try again.",
    selectCard: "Please select credit card",
    duplicateService: "Vehicle Duplicate with same service",
    duplicateCarPark: "Vehicle Duplicate with same carpark",
    noDateSetting:
      "Date setting Not found. Please contact our customer service",
    duplicateRenewal:
      'Renewal request submitted before Please use the received payment link to pay, or re-generate the payment link at "Profile > Invoice"',
    invalidMethod:
      "The payment method is not available for this car park, please select another method.",
    serverError: "Server error. Please try again later.",
    selectMethod: "Please select payment method",
    acceptTAC: "Please accept T&C first",
    pleaseLogin: "Please login to your account.",
    reservation: "Reservation",
    reserve: "Reserve Quota",
    reserveQuotaRemark:
      "Since your application is being process and this space is reserved for residents only, you may reserve this space first. Our staff will contact you soon for the arrangement.",
    collectMethod: "Choose Collection Method",
    parkingCard: "Parking Card",
    selectCollectionMethod: "Please select collection method",
    parkingCardCollect: {
      mso_office: "MSO Office",
      mail: "Post by mail",
      self_pickup: "Self pickup at car park",
    },
    invoiceCancelled: "This invoice has been cancelled.",
  },
  oneTimePayment: {
    tempChange: "Change LPN - temporary",
    permChange: "Change LPN - permanent",
    paymentSummary: "Payment summary",
    servicePackage: "Service package",
    servicePeriod: "Service period",
    invoiceID: "Invoice id",
    issueDate: "Issue date",
    dueDate: "Due date",
    customerName: "Customer name",
    email: "Email",
    tel: "Tel",
    vehicle: "Vehicle",
    newPlate: "New Plate",
    total: "Total",
    invoiceConfirmed:
      'The payment for this invoice is confirmed. Please check your payment status on "Profile > Invoice" page.',
    invoicePending:
      "We are processing your payment request submitted before. You can change your payment method here, but your previous invoice will be cancelled.",
    invoiceCancelled: "This invoice has been cancelled.",
    paymentLinkInvalid:
      "The link may be expired or invalid , please try again.",
  },
  payment711: {
    remark: "Remark",
    paymentInfo: "Payment Info",
    scanReceipt: "Scan Receipt",
    remark1:
      "This QR code is valid for 24 hours, please settle your payment ASAP. You need to select payment method again in order to obtain a new QR code after expiration.",
    remark2:
      "Please upload a receipt after your payment. There is OCR verification which scans the receipt image and confirm.",
    remark3: "Please ensure to upload a valid receipt for verification.",
    remark4: "Tab [Confirm Receipt] after uploading receipt.",
    remark5:
      "Please complete the payment at least {{paymentBuffer}} days prior to expiry date of monthly parking service and upload the receipt verification.",
    payAt: "Pay at 7-Eleven",
    showQrCode: "Please show the QR code(s) to Cashier for payment",
    refresh: "(Please refresh when you return to this page)",
    incorrectMonth:
      "(Please close and refresh the browser if the display month is not correct)",
    servicePackage: "Service package: ",
    servicePeriod: "Service period: ",
    vehicleRegMark: "Vehicle Registration Mark: ",
    uploadAfterPayment: "Please upload the receipt after payment",
    limit: "Limit: ",
    uploadReceipt: "Upload Receipt",
    backHome: "Back to home page",
    scanFail0:
      "Sorry! The system can’t read your receipt and process OCR verification, please upload the receipt again.",
    scanFail1:
      "1. Take a clear picture of your receipt, where amount, bill number and payment date are readable without any blur.",
    scanFail2: "2. Take the receipt image somewhere brightly lit.",
    scanFail3: "3. Turn flash off to avoid glare on your receipt.",
    scanFail4: "4. Take the receipt on a flat surface.",
    exceedLimit:
      "You have failed to upload and verify the receipt for 3 times. Please contact our customer service team for further assistance.",
  },
  paymentReceipt: {
    content: "Receipt content",
    billNumber: "Bill number",
    amount: "Receipt amount",
    date: "Receipt payment date",
    time: "Receipt payment time",
    remark:
      "Make sure the receipt information is correct and match with the payment page.",
    uploadAgain: "Re-upload",
    confirmAmount: "Confirm Amount",
    confirm: "Confirm Receipt",
  },
  paymentFPS: {
    payBy: "Pay by FPS",
    remark0: "After selected FPS,",
    remark1Mobile: "Please make a screenshot of the QR code.",
    remark2Mobile: "Open the bank’s app with your mobile phone.",
    remark3Mobile:
      "Select ‘Pay with FPS’ and login to your selected bank account.",
    remark4Mobile:
      "Then upload the saved QR code in your bank’s app, and proceed with the payment.",
    remark1Desktop: "A QR code will be generated on the computer’s screen.",
    remark2Desktop: "Open the bank’s app with your mobile phone.",
    remark3Desktop:
      "Select ‘Pay with FPS’ and login to your selected bank account.",
    remark4Desktop:
      "Use your mobile phone to scan the QR code on your desktop, and proceed with the payment.",
    scanCode:
      "Please open the bank’s app with your mobile phone, to scan the QR code on your desktop",
    completePayment: "Complete payment",
  },
  paymentCQM: {
    payBy: "Pay by cheque at HSBC (CQM)",
    checkRemark:
      "The cheque should be payable to “Let’s Park Company (Hong Kong) Limited”",
    billingType: "Bill Type: ",
    billAccountNumber: "Bill Account Number:",
    uploadAfterPayment: "Please upload the receipt after payment",
    completePayment: "Complete payment",
    remark1:
      "Select CQM. A 18-digit bill account number will be sent to you via SMS and email.",
    remark2:
      "Prepare a crossed cheque payable to “Let’s Park Company (Hong Kong) Limited” and approach a Cheque Deposit Machine at HSBC.",
    remark3:
      "On CQM: Select [Bill Payment] > [Other companies] > [Let’s Park Company (Hong Kong) Limited] > [01 Payment of Monthly Parking]. Then, key in cheque amount and the 18-digit bill account number.",
    remark4: "Insert cheque and keep the advice after payment.",
    remark5: "Tap ‘camera logo‘,  Upload Receipt to confirm payment.",
    payofMonthly: "Payment of Monthly Parking",
    payofother: "Other Payment",
  },
  paymentSuccess: {
    title: "Payment successful",
    invoice: "Your invoice number is",
    smsRemark:
      "Once the payment is confirmed, you will receive a SMS / email confirmation.",
    arrangeRemark:
      "Please contact the carpark manager / officer to arrange selection of the reserved parking bay.",
    remark: "You can access to your booking details and manage account.",
    back: "Back to Homepage",
  },
  paymentError: {
    title: "Error",
    remark: "Please try again",
    search: "Search again",
  },
  paymentExpired: {
    title: "The link may be expired",
    remark1:
      "Payment links will be invalid if the invoice is cancelled or expired.",
    remark2:
      "If your link is valid, please close all the webpages of {{company}} and try again.",
    remark3: "You can access to your booking details and manage account.",
    profile: "Go to Profile",
  },
  paymentPendingSMS: {
    title: "Check your mobile number",
    phone: "Payment details sent to this mobile number",
    remark:
      "Click the link sent to this number to view and to complete your purchase.",
    send: "Resend SMS",
    sendAgain: "[SMS can be resend after {{sec}} seconds]",
    back: "Back to Homepage",
    sent: "The payment link has been resent.",
  },
  paymentProcessing: {
    title: "Payment is under progress",
    invoice: "Your invoice number is {{id}}",
    smsRemark:
      "Once the payment is confirmed, you will receive a SMS / email confirmation.",
    remark: "You can access to your booking details and manage account.",
    back: "Back to Homepage",
  },
  reserveSuccess: {
    title: "Reservation successful",
    invoice: "Your invoice number is",
    remark:
      "Once the application is approved, you will receive a payment link.",
    back: "Back to Homepage",
  },
  accountSettings: {
    basic: "Basic Information",
    firstName: "First name",
    lastName: "Last name",
    title: "Title",
    lang: "Preferred language",
    company: "Company",
    jobTitle: "Job title",
    email: "Email address",
    countryCode: "Country Code",
    phone: "Phone number",
    code: "Verification Code",
    send: "Send Code",
    address: "Address",
    room: "Room",
    floor: "Floor",
    block: "Block",
    building: "Name of Building / Tower",
    street: "Number & Name of Street",
    district: "District",
    save: "Save Changes",
    required: "This field is required!",
    invalidEmail: "Please enter a valid email address!",
    emailExist: "Email exist!",
    invalidPhone: "Please enter a valid number!",
    phoneExist: "Phone number exist!",
    invalidCode: "Please enter valid verification code!",
    success: "Update successful",
    mr: "Mr.",
    ms: "Ms.",
    mrs: "Mrs.",
    en: "English",
    tc: "Traditional Chinese",
    fail: "Update failed. Please try again",
    viewProfile: "View Profile",
    editProfile: "Edit Profile",
    changePhoto: "Change Photo",
    editVehicle: "Edit Vehicles",
    profileImage: "Profile Image",
    upload: "Upload Photo",
    exceedLimit: "Cannot upload image larger than 1 MB",
    uploadFail: "Failed to upload photo. Please try again.",
    vehicles: "My Vehicles",
    carPlate: "Car Plate",
    carType: "Car Type",
    default: "Default",
    setDefault: "Set Default",
    uploading: "Uploading...",
  },
  profile: {
    service: "Service",
    invoice: "Invoice",
    payNow: "Pay Now",
    pay: "Pay",
    cancel: "Cancel",
    check: "Check",
    carparkName: "Carpark Name",
    servicePackage: "Service Package",
    vehicle: "Vehicle",
    status: "Status",
    date: "Date",
    total: "Total",
    orderID: "Order id",
    invoiceID: "Invoice id",
    paymentCancelled: "Payment Cancelled",
    tempVehicleChange: "Vehicle Change - Temporary",
    permVehicleChange: "Vehicle Change - Permanent",
    monthlyFee: "Monthly Parking Fee",
    hourlyFee: "Hourly Parking Fee",
    confirmed: "Confirmed",
    pendingRenew: "Pending to renew",
    pendingPayment: "Pending to payment",
    pendingProcess: "Pending to process",
    cancelled: "Cancelled",
    expired: "Expired",
    terminated: "Terminated",
    error: "Error",
    empty: "No record were found",
    sortDescend: "Sort by descend",
    sortAscend: "Sort by ascend",
    endDate: "Service end date",
    monthly: "Monthly",
    hourly: "Hourly",
    renewOn: "Renew on",
    configuration: "Configuration",
    merchant: "Merchant name",
    displayMore: "Display More",
    renew: "Renew",
    vehicleChange: "Vehicle Change",
    months: "Months",
    type: "Type",
    download: "Download",
    buyNew: "Buynew",
    bookingPeriod: "Booking Period",
    changeRequestInfo: "Change Request Information",
    temporary: "Temporary",
    permanent: "Permanent",
    from: "From",
    to: "To",
    newPlate: "New plate no.",
    totalPayable: "Total Payable Amount",
    change: "Change",
    effectiveDate: "Effective Date",
    alreadyChanged: "You have changed car plate this month",
    chooseCarType: "Please choose car type",
    enterCarPlate: "Please enter your car plate",
    selectNewPlate: "Please select new plate",
    selectVehicle: "Please select a vehicle",
    vehicleChangeSuccess: "Vehicle Change Success",
    changeService: "Change Service",
    changeCarPlate: "Change Car Plate No.",
    instantRenew: "Renew",
    contractRenewal: "Contract Renewal",
    month: "Month",
    chooseService: "Please choose service",
    chooseCarpark: "Please choose carpark",
  },
  news: {
    title: "News & Promotions",
    readmore: "Read More ...",
  },
  service: {
    title: "Service",
    subTitle: "Our extensive professional car park services",
  },
  ocrVerification: {
    title: "OCR Verification",
    back: "Back",
    next: "Next",
    heading1: "Step 1: Terms & Conditions",
    heading2: "Step 1: Upload Documents",
    heading3: "Step 2: Validation Result",
    agree: "I have read and agree to the above terms and conditions",
    imageReference: "Image Reference",
    uploadIDCard: "Upload ID Card",
    uploadAddressProof: "Upload Address Proof",
    addressProof: "Address Proof",
    clp: "Electric Bill (CLP)",
    hk: "Electric Bill (HK Electric)",
    water: "Water Bill",
    towngas: "Gas Bill",
    selectAddressProofType: "Please select address proof type before upload",
    preview: "Preview",
    previewRemark:
      "Remark: Bottom one third of this image is masked before upload.",
    previewRemark2: "Please set the image to landscape and right-side-up.",
    retry: "Retry",
    confirm: "Confirm",
    residentName: "Resident Name",
    buildingName: "Building Name",
    nameNotMatch:
      "The name on your HKID card does not match with your address proof.",
    invalidBuilding:
      "The uploaded address proof does not match with the system record.",
    stillHaveQuestions: "Still have questions?",
    message: "Your message here...",
    remark:
      "Remark: The documents will be removed after auto/manual validation.",
    successTitle: "Well done",
    successContent: "You may now book parking space.",
    failTitle: "There is something wrong",
    failContent:
      "Our staff will follow up on your submission and get back to you shortly. \n" +
      "Please click “Next” and reserve your parking space first. Our system will hold your reservation up to 24 hours.",
    hkidMaxRetry:
      "You have failed to upload and verify the HKID Card for 3 times. \n" +
      "Please contact our customer support team by phone {{phone}} or send an email to {{email}} for assistance.",
    addressProofMaxRetry:
      "You have failed to upload and verify the bill for 3 times. \n" +
      "Please contact our customer support team by phone {{phone}} or send an email to {{email}} for assistance.",
    hkidImage: "ID Card",
    addressProofImage: "Address Proof",
    notRecognised:
      "Sorry! The system can’t read your receipt and process OCR verification, please upload the receipt again. \n\n" +
      "1.Take a clear picture of your receipt, where amount, bill number and payment date are readable without any blur. \n" +
      "2. Take the receipt image somewhere brightly lit.\n" +
      "3. Turn flash off to avoid glare on your receipt. \n" +
      "4. Take the receipt on a flat surface. ",
    updateFailed: "Failed to update remark. Please try again.",
    ocrError:
      "Your OCR verification for this car park is under process, please wait for the reply from our staff.",
    ocrApproved: "Your OCR verification for this car park already passed.",
    ocrCheckStatusFailed:
      "Failed to get your OCR verification status. Please try again.",
    rotateLeft: "Rotate Left",
    rotateRight: "Rotate Right",
  },
  maintain: {
    maintainMessage:
      "System Upgrade\n\nWe are performing a system upgrade of our website. During the upgrading period, users may experience delays in loading web pages or the site can’t be reached.\n\nWe strive for providing quality website service at all times. Thank you for your support.\n",
  },
};
