import React from "react";
import { NavLink } from "react-router-dom";
import { Menu } from "antd";
import { LOGIN, REGISTRATION } from "settings/constant";
import { useTranslation } from "react-i18next";

const MobileAuthMenu = ({ showRegister, className, onClose }) => {
  const { t } = useTranslation();
  return (
    <Menu className={className}>
      {showRegister && (
        <Menu.Item key="1" style={{ padding: "0 35px" }}>
          <NavLink to={REGISTRATION} onClick={() => onClose()}>
            {t("signUp")}
          </NavLink>
        </Menu.Item>
      )}
      <Menu.Item key="0" style={{ padding: "0 35px" }}>
        <NavLink to={LOGIN} onClick={() => onClose()}>
          {t("signIn")}
        </NavLink>
      </Menu.Item>
    </Menu>
  );
};

export default MobileAuthMenu;
