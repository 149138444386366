import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Select from "react-select";

const customStyles = {
  indicatorSeparator: () => null,
  dropdownIndicator: (base) => ({
    ...base,
    color: "#000",
    "&:hover": { color: "#000" },
  }),
  control: () => ({
    width: "100%",
    minWidth: 170,
    height: 38,
    display: "flex",
    flexDirection: "row",
    border: "1px solid #E6E6E6",
    borderRadius: 3,
    color: "#000",
  }),
  placeholder: () => ({
    color: "#000",
  }),
  option: (styles, { isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isSelected
        ? "rgba(245, 2, 8, 1)"
        : isFocused
        ? "rgba(245, 2, 8, 0.2)"
        : null,
      color: isSelected ? "#fff" : "#000",
      ":active": {
        ...styles[":active"],
        backgroundColor: "rgba(245, 2, 8, 0.1)",
      },
    };
  },
};

const ReactSelect = ({
  placeholder,
  options,
  onChange,
  style,
  value: selected,
}) => {
  const { i18n } = useTranslation();

  const [translatedOptions, setTranslatedOptions] = useState([]);
  const [translatedValue, setTranslatedValue] = useState({});

  const language = i18n.language === "en" ? "en" : "tc";

  const _renderOption = (option) => {
    return {
      ...option,
      label: (
        <span>
          {option.icon && option.icon}
          {option[`name_${language}`] || option.label}
        </span>
      ),
    };
  };

  useEffect(() => {
    if (options.length > 0) {
      const translated = options.map((option) => _renderOption(option));
      setTranslatedOptions(translated);
    }

    if (selected || selected === "") {
      setTranslatedValue(_renderOption(selected));
    }
  }, [i18n.language, options.length, selected]);

  return (
    <Select
      placeholder={placeholder}
      options={translatedOptions}
      onChange={onChange}
      styles={{ ...customStyles, ...style }}
      value={translatedValue}
      isSearchable={false}
    />
  );
};

// DropDown.propTypes = {
//   options: PropTypes.arrayOf(
//     PropTypes.shape({
//       id: PropTypes.string,
//       label: PropTypes.string,
//       value: PropTypes.string,
//     })
//   ),
// };

export default ReactSelect;
