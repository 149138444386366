import UploadActionTypes from "./actionTypes";
import BaseActions, { APIRequestGenerator } from "../../base/BaseActions";
import APIHelper from "../../base/APIHelper";

const RESOURCE = "upload";
const MERCHANT = process.env.REACT_APP_MERCHANT;

function upload(data, token) {
  return async (dispatch) => {
    const { url } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/upload`,
      null,
      token
    );
    try {
      const response = await APIHelper("POST", url.toString(), data, false, {
        Accept: "application/json, text/plain, /",
      });
      dispatch({
        type: UploadActionTypes.UPLOAD_IMAGE,
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

const UploadActions = {
  ...BaseActions(RESOURCE, UploadActionTypes),
  upload,
};
export default UploadActions;
