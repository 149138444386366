import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Loadable from "react-loadable";
import Cookies from "universal-cookie";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Layout from "./container/Layout/Layout";
import {
  ABOUT_US,
  ACTIVATE_WITH_MOBILE,
  ACTIVATION_FORM,
  ACTIVATE_REGISTRATION,
  CANCELLATION,
  DISCLAIMER,
  PRIVACY_POLICY,
  TAC,
  LETSPARK_TAC,
  HOW_IT_WORKS,
  ACCOUNT_SETTINGS,
  CAR_PARK_DETAIL,
  CAR_PARK_LIST,
  CHECK_MAILBOX,
  CONFIRM_CHANGE_PASSWORD,
  CONFIRM_REGISTRATION,
  CORPORATE_CONFIRM_REGISTRATION,
  FORGET_PASSWORD,
  HOME_PAGE,
  INDIVIDUAL_FORM,
  LOGIN,
  MOBILE_LOGIN,
  NEWS,
  ONE_TIME_PAYMENT,
  PAYMENT,
  PAYMENT_ERROR,
  PAYMENT_EXPIRED,
  PAYMENT_OFFLINE_711,
  PAYMENT_OFFLINE_CQM,
  PAYMENT_OFFLINE_FPS,
  PAYMENT_PENDING_SMS,
  PAYMENT_PROCESSING,
  PAYMENT_SUCCESS,
  PROFILE,
  REGISTER_CORPORATE_FORM,
  REGISTER_INDIVIDUAL_FORM,
  REGISTRATION,
  RESET_PASSWORD,
  SERVICE,
  OCR_VERIFICATION,
  RESERVE_SUCCESS,
  MAINTENANCE,
} from "./settings/constant";
import actions, { useActions } from "./redux/actions";
import ErrorHandler from "./base/ErrorHandler";
import { getMultiLangProperty } from "./library/helpers/generalHelpers";

/**
 *
 * Public Routes
 *
 */
const Loading = () => null;

const routes = [
  {
    path: HOME_PAGE,
    component: Loadable({
      loader: () => import(/* webpackChunkName: "Home" */ "./container/Home"),
      loading: Loading,
      modules: ["Home"],
    }),
    exact: true,
  },
  {
    path: ABOUT_US,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "About" */ "./container/Info/AboutUs"),
      loading: Loading,
      modules: ["AboutUs"],
    }),
    exact: true,
  },
  {
    path: ACTIVATE_WITH_MOBILE,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "About" */ "./container/Auth/SignUp/containers/ActivateMobile"
        ),
      loading: Loading,
      modules: ["ActivateMobile"],
    }),
    exact: true,
  },
  {
    path: ACTIVATION_FORM,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "About" */ "./container/Auth/SignUp/containers/ActivateForm"
        ),
      loading: Loading,
      modules: ["ActivateForm"],
    }),
    exact: true,
  },
  {
    path: ACTIVATE_REGISTRATION,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "About" */ "./container/Auth/SignUp/containers/ActivateSuccess"
        ),
      loading: Loading,
      modules: ["ActivateSuccess"],
    }),
    exact: true,
  },
  {
    path: CANCELLATION,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "Cancellation" */ "./container/Info"),
      loading: Loading,
      modules: ["Cancellation"],
    }),
    exact: true,
  },
  {
    path: DISCLAIMER,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "Disclaimer" */ "./container/Info"),
      loading: Loading,
      modules: ["Disclaimer"],
    }),
    exact: true,
  },
  {
    path: PRIVACY_POLICY,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "PrivacyPolicy" */ "./container/Info"),
      loading: Loading,
      modules: ["PrivacyPolicy"],
    }),
    exact: true,
  },
  {
    path: TAC,
    component: Loadable({
      loader: () => import(/* webpackChunkName: "TAC" */ "./container/Info"),
      loading: Loading,
      modules: ["TAC"],
    }),
    exact: true,
  },
  {
    path: LETSPARK_TAC,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "LetsParkTac" */ "./container/Info"),
      loading: Loading,
      modules: ["LetsParkTac"],
    }),
    exact: true,
  },
  {
    path: HOW_IT_WORKS,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "FAQ" */ "./container/Info/FAQ"),
      loading: Loading,
      modules: ["FAQ"],
    }),
    exact: true,
  },
  {
    path: LOGIN,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "Login" */ "./container/Auth/Login"),
      loading: Loading,
      modules: ["Login"],
    }),
  },
  {
    path: REGISTRATION,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "SignUp" */ "./container/Auth/SignUp"),
      loading: Loading,
      modules: ["SignUp"],
    }),
  },
  {
    path: INDIVIDUAL_FORM,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "IndividualOptions" */ "./container/Auth/SignUp/containers/IndividualOptions"
        ),
      loading: Loading,
      modules: ["IndividualOptions"],
    }),
  },
  {
    path: REGISTER_INDIVIDUAL_FORM,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "IndividualForm" */ "./container/Auth/SignUp/containers/IndividualForm"
        ),
      loading: Loading,
      modules: ["IndividualForm"],
    }),
  },
  {
    path: REGISTER_CORPORATE_FORM,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "CorporateForm" */ "./container/Auth/SignUp/containers/CorporateForm"
        ),
      loading: Loading,
      modules: ["CorporateForm"],
    }),
  },
  {
    path: CONFIRM_REGISTRATION,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "IndividualSuccess" */ "./container/Auth/SignUp/containers/IndividualSuccess"
        ),
      loading: Loading,
      modules: ["IndividualSuccess"],
    }),
  },
  {
    path: CORPORATE_CONFIRM_REGISTRATION,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "CorporateSuccess" */ "./container/Auth/SignUp/containers/CorporateSuccess"
        ),
      loading: Loading,
      modules: ["CorporateSuccess"],
    }),
  },
  {
    path: FORGET_PASSWORD,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "ForgetPassword" */ "./container/Auth/ForgetPassword"
        ),
      loading: Loading,
      modules: ["ForgetPassword"],
    }),
  },
  {
    path: CHECK_MAILBOX,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "CheckMailbox" */ "./container/Auth/CheckMailbox"
        ),
      loading: Loading,
      modules: ["CheckMailbox"],
    }),
  },
  {
    path: RESET_PASSWORD,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "ResetPassword" */ "./container/Auth/ResetPassword"
        ),
      loading: Loading,
      modules: ["ResetPassword"],
    }),
  },
  {
    path: CONFIRM_CHANGE_PASSWORD,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "ConfirmChangePassword" */ "./container/Auth/ConfirmChangePassword"
        ),
      loading: Loading,
      modules: ["ConfirmChangePassword"],
    }),
  },
  {
    path: MOBILE_LOGIN,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "MobileLogin" */ "./container/Auth/MobileLogin"
        ),
      loading: Loading,
      modules: ["MobileLogin"],
    }),
  },
  {
    path: `${CAR_PARK_DETAIL}/:id`,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "CarparkDetail" */ "./container/CarparkDetail"
        ),
      loading: Loading,
      modules: ["CarparkDetail"],
    }),
  },
  {
    path: CAR_PARK_LIST,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "Listing" */ "./container/Listing/Listing"),
      loading: Loading,
      modules: ["Listing"],
    }),
  },
  {
    path: NEWS,
    component: Loadable({
      loader: () => import(/* webpackChunkName: "News" */ "./container/News"),
      loading: Loading,
      modules: ["News"],
    }),
    exact: true,
  },
  {
    path: `${NEWS}/:id`,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "NewsDetail" */ "./container/NewsDetail"),
      loading: Loading,
      modules: ["NewsDetail"],
    }),
  },
  {
    path: ONE_TIME_PAYMENT,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "OneTimePayment" */ "./container/OneTimePayment"
        ),
      loading: Loading,
      modules: ["OneTimePayment"],
    }),
  },
  {
    path: PAYMENT_OFFLINE_711,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "711" */ "./container/OfflinePayment/711"),
      loading: Loading,
      modules: ["711"],
    }),
  },
  {
    path: PAYMENT_OFFLINE_FPS,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "FPS" */ "./container/OfflinePayment/FPS"),
      loading: Loading,
      modules: ["FPS"],
    }),
  },
  {
    path: PAYMENT_OFFLINE_CQM,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "FPS" */ "./container/OfflinePayment/CQM"),
      loading: Loading,
      modules: ["CQM"],
    }),
  },
  {
    path: PAYMENT_SUCCESS,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "PaymentSuccess" */ "./container/PaymentSuccess"
        ),
      loading: Loading,
      modules: ["PaymentSuccess"],
    }),
  },
  {
    path: PAYMENT_ERROR,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "PaymentError" */ "./container/PaymentError"
        ),
      loading: Loading,
      modules: ["PaymentError"],
    }),
  },
  {
    path: PAYMENT_EXPIRED,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "PaymentExpired" */ "./container/PaymentExpired"
        ),
      loading: Loading,
      modules: ["PaymentExpired"],
    }),
  },
  {
    path: PAYMENT_PENDING_SMS,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "PaymentPendingSMS" */ "./container/PaymentPendingSMS"
        ),
      loading: Loading,
      modules: ["PaymentPendingSMS"],
    }),
  },
  {
    path: PAYMENT_PROCESSING,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "PaymentProcessing" */ "./container/PaymentProcessing"
        ),
      loading: Loading,
      modules: ["PaymentProcessing"],
    }),
  },
  {
    path: PRIVACY_POLICY,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "Privacy" */ "./container/Privacy/Privacy"),
      loading: Loading,
      modules: ["Privacy"],
    }),
  },
  {
    path: `${SERVICE}/:id`,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "Service" */ "./container/Service"),
      loading: Loading,
      modules: ["Service"],
    }),
    exact: true,
  },
  {
    path: SERVICE,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "Service" */ "./container/Service"),
      loading: Loading,
      modules: ["Service"],
    }),
  },
  {
    path: RESERVE_SUCCESS,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "ReserveSuccess" */ "./container/ReserveSuccess"
        ),
      loading: Loading,
      modules: ["ReserveSuccess"],
    }),
  },
  {
    path: MAINTENANCE,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "ReserveSuccess" */ "./container/Maintenance"
        ),
      loading: Loading,
      modules: ["Maintenance"],
    }),
  },
];

/**
 *
 * Protected Route Component
 *
 */
const AccountSettingsPage = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "AccountSettings" */ "./container/User/AccountSettings"
    ),
  loading: Loading,
  modules: ["AccountSettings"],
});

const ProfilePage = Loadable({
  loader: () =>
    import(/* webpackChunkName: "Profile" */ "./container/User/Profile"),
  loading: Loading,
  modules: ["Profile"],
});

const PaymentPage = Loadable({
  loader: () => import(/* webpackChunkName: "Payment" */ "./container/Payment"),
  loading: Loading,
  modules: ["Payment"],
});

const OCRVerificationPage = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "OCR Verification" */ "./container/OCRVerification"
    ),
  loading: Loading,
  modules: ["OCRVerification"],
});

/**
 *
 * Not Found Route Component
 *
 */

const NotFound = Loadable({
  loader: () =>
    import(/* webpackChunkName: "NotFound" */ "./container/404/404"),
  loading: Loading,
  modules: ["NotFound"],
});

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { t } = useTranslation();
  const cookies = new Cookies();
  const token = cookies.get("token");
  if (!token) window.alert(t("payment.pleaseLogin"));
  return (
    <Route
      render={(props) =>
        token ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: LOGIN,
              state: { from: props.location },
            }}
          />
        )
      }
      {...rest}
    />
  );
};

/**
 *
 * Overall Router Component
 *
 */

const Routes = () => {
  const cookies = new Cookies();
  const lang = cookies.get("lang", { path: "/" }) || "en";
  const SiteActions = useActions(actions.SiteActions);
  const MerchantActions = useActions(actions.MerchantActions);
  const merchant = useSelector((state) => state.merchant);
  const { favicon, web_name, desc, desc_tc } = merchant || {};

  const fetchMerchant = () => {
    MerchantActions.getOne()
      .then((response) => {
        const { maintenance_domain = "" } = response;
        if (maintenance_domain) {
          if (
            window.location.href.includes(maintenance_domain) &&
            !window.location.pathname.includes("/maintain")
          ) {
            window.location.href = "/maintain";
          }
        }
      })
      .catch(ErrorHandler);
  };

  const fetchSiteConfig = () => {
    SiteActions.getSiteConfig().catch(ErrorHandler);
  };

  const setDefaultLanguage = () => {
    i18next.changeLanguage(lang);
  };

  useEffect(() => {
    fetchSiteConfig();
    setDefaultLanguage();
    fetchMerchant();
  }, []);

  useEffect(() => {
    document.getElementById("favicon").href = favicon;
    document.title = web_name;
    document.getElementById("description").content = `${desc_tc} ${desc}`;
    document.getElementById("title").content = web_name;
  }, [favicon, web_name, merchant, lang]);

  return (
    <Layout>
      <Switch>
        {routes.map(({ path, component, exact = false }) => (
          <Route key={path} path={path} exact={exact} component={component} />
        ))}
        <ProtectedRoute
          path={ACCOUNT_SETTINGS}
          component={AccountSettingsPage}
        />
        <ProtectedRoute path={PROFILE} component={ProfilePage} />
        <ProtectedRoute path={PAYMENT} component={PaymentPage} />
        <ProtectedRoute
          path={OCR_VERIFICATION}
          component={OCRVerificationPage}
        />
        <Route component={NotFound} />
      </Switch>
    </Layout>
  );
};

export default Routes;
