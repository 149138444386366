import React, { useRef, useState } from "react";
import { Menu } from "antd";
import useOnClickOutside from "library/hooks/useOnClickOutside";
import { NavLink, withRouter } from "react-router-dom";
import { ACCOUNT_SETTINGS, PROFILE } from "settings/constant";
import Cookies from "universal-cookie";
import { useTranslation } from "react-i18next";

const cookies = new Cookies();

const ProfileMenu = ({ avatar, history }) => {
  const [state, setState] = useState(false);
  const { t } = useTranslation();
  const handleDropdown = () => {
    setState(!state);
  };
  const closeDropdown = () => {
    setState(false);
  };
  const dropdownRef = useRef(null);
  useOnClickOutside(dropdownRef, () => setState(false));
  function handleLogout() {
    cookies.set("token", "");
    history.push("/");
    window.location.reload();
  }

  return (
    <div className="avatar-dropdown" ref={dropdownRef}>
      <div className="dropdown-handler" onClick={handleDropdown}>
        {avatar}
      </div>
      <Menu className={`dropdown-menu ${state ? "active" : "hide"}`}>
        <Menu.Item onClick={closeDropdown} key="0">
          <NavLink to={ACCOUNT_SETTINGS}>{t("navbar.account")}</NavLink>
        </Menu.Item>
        <Menu.Item onClick={closeDropdown} key="1">
          <NavLink to={PROFILE}>{t("navbar.profile")}</NavLink>
        </Menu.Item>
        <Menu.Item key="2">
          <button onClick={handleLogout}>{t("logout")}</button>
        </Menu.Item>
      </Menu>
    </div>
  );
};

export default withRouter(ProfileMenu);
