import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "antd";
import { useSelector } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import { FacebookFilled } from "@ant-design/icons";
import {
  CompanyDescription,
  Contact,
  ContentWrapper,
  CopyrightWrapper,
  FooterWrapper,
  HelpfulLinks,
  News,
} from "./Footer.style";
import Heading from "../../../components/UI/Heading/Heading";

import { getMultiLangProperty } from "../../../library/helpers/generalHelpers";
import {
  ABOUT_US,
  CANCELLATION,
  CAR_PARK_LIST,
  DISCLAIMER,
  HOW_IT_WORKS,
  LETSPARK_TAC,
  LOGIN,
  NEWS,
  PRIVACY_POLICY,
  SERVICE,
  TAC,
} from "../../../settings/constant";
import actions, { useActions } from "../../../redux/actions";
import Text from "../../../components/UI/Text/Text";

const cookies = new Cookies();

const Footer = ({ resized = null }) => {
  const { t, i18n } = useTranslation();
  const token = cookies.get("token");
  const NewsActions = useActions(actions.NewsActions);
  const merchant = useSelector((state) => state.merchant);
  const {
    landing: {
      search: { displaymain: showCarpark } = {},
      services: { displaynav: showService } = {},
      news: { displaynav: showNews } = {},
      about: { displaynav: showAbout } = {},
      faq: { displaymain: showFAQ } = {},
      footer: {
        address: showAddress,
        email: showEmail,
        fax: showFax,
        phone: showPhone,
        cancel: showCancellation,
        companyname: showCompanyName,
        contact: showContact,
        disclaimer: showDisclaimer,
        helpful: showHelpfulLink,
        pp: showPrivacy,
        psla: showLPTAC,
        tac: showTAC,
        facebook: showFacebook,
      } = {},
      footercontent = {},
    } = {},
  } = useSelector((state) => state.site);
  const [news, setNews] = useState([]);
  const columns =
    [showNews, showHelpfulLink, showContact].filter((x) => x === true).length +
    1;
  const colSpan = 24 / columns;

  useEffect(() => {
    NewsActions.getList()
      .then((response) => {
        if (Array.isArray(response)) {
          const filteredResult = response
            .filter((n) => n.status === true || n.status === "true")
            .sort(
              (a, b) =>
                moment(a.date).format("YYYY-MM-DD") -
                moment(b.date).format("YYYY-MM-DD")
            );
          const finalResult =
            filteredResult.length > 3
              ? filteredResult.slice(0, 3)
              : filteredResult;
          setNews(finalResult);
        } else {
          setNews([]);
        }
      })
      .catch(() => {
        setNews([]);
      });
  }, []);

  const _renderCompanyDescription = () => (
    <CompanyDescription>
      <img src={merchant.logo} width={150} />
      {showCompanyName && (
        <Heading
          as="h4"
          content={getMultiLangProperty(merchant, "name", i18n.language)}
        />
      )}
      <Text content={getMultiLangProperty(merchant, "desc", i18n.language)} />
    </CompanyDescription>
  );

  const _renderHelpfulLinks = () => (
    <HelpfulLinks>
      <Heading as="h4" content={t("footer.helpful")} />
      <Row>
        <Col xs={24} sm={12}>
          {!token && (
            <div>
              <Link to={LOGIN}>
                <span> {t("signIn")}</span>
              </Link>
            </div>
          )}
          {showCarpark && (
            <div>
              <Link to={CAR_PARK_LIST}>
                <span> {t("footer.carpark")}</span>
              </Link>
            </div>
          )}
          {showService && (
            <div>
              <Link to={SERVICE}>
                <span> {t("footer.service")}</span>
              </Link>
            </div>
          )}
          {showNews && (
            <div>
              <Link to={NEWS}>
                <span> {t("footer.latest")}</span>
              </Link>
            </div>
          )}
          {showAbout && (
            <div>
              <Link to={ABOUT_US}>
                <span> {t("footer.about")}</span>
              </Link>
            </div>
          )}
          {showFAQ && (
            <div>
              <Link to={HOW_IT_WORKS}>
                <span> {t("footer.faq")}</span>
              </Link>
            </div>
          )}
        </Col>
        <Col xs={24} sm={12}>
          {showLPTAC && (
            <div>
              <Link to={LETSPARK_TAC}>
                <span> {t("footer.lpTac")}</span>
              </Link>
            </div>
          )}
          {showTAC && (
            <div>
              <Link to={TAC}>
                <span> {t("footer.tac")}</span>
              </Link>
            </div>
          )}
          {showPrivacy && (
            <div>
              <Link to={PRIVACY_POLICY}>
                <span> {t("footer.privacy")}</span>
              </Link>
            </div>
          )}
          {showDisclaimer && (
            <div>
              <Link to={DISCLAIMER}>
                <span> {t("footer.disclaimer")}</span>
              </Link>
            </div>
          )}
          {showCancellation && (
            <div>
              <Link to={CANCELLATION}>
                <span> {t("footer.cancellation")}</span>
              </Link>
            </div>
          )}
        </Col>
      </Row>
    </HelpfulLinks>
  );

  const _renderNews = () => (
    <News>
      <Heading as="h4" content={t("footer.news")} />
      {Array.isArray(news) &&
        news.map((n, index) => (
          <div className="news-card" key={index}>
            <div>
              <p>{moment(n.date).format("DD/MM/YYYY")}</p>
              <h3>
                <Link to={`${NEWS}/${n._id}`}>
                  <span>{getMultiLangProperty(n, "topic", i18n.language)}</span>
                </Link>
              </h3>
            </div>
          </div>
        ))}
    </News>
  );

  const _renderContact = () => (
    <Contact>
      <Heading as="h4" content={t("footer.contact")} />
      {showAddress && (
        <div>
          <Text
            content={`${t("footer.address")}: ${getMultiLangProperty(
              footercontent,
              "address",
              i18n.language
            )}`}
          />
        </div>
      )}
      {showPhone && (
        <div>
          {`${t("footer.phone")}: `}
          <a href={`tel:${footercontent.phone}`}>{footercontent.phone}</a>
        </div>
      )}
      {showFax && (
        <div>
          <Text content={`${t("footer.fax")}: ${footercontent.fax}`} />
        </div>
      )}
      {showEmail && (
        <div>
          {`${t("footer.email")}: `}
          <a href={`mailto:${footercontent.email}`}>{footercontent.email}</a>
        </div>
      )}
      {showFacebook && (
        <div>
          <a
            href="javascript:window.open('http://www.facebook.com/sharer.php?u='+encodeURIComponent(document.location.href)+'&t='+encodeURIComponent(document.title),'_blank','toolbar=yes, location=yes, directories=no, status=no, menubar=yes, scrollbars=yes, resizable=no, copyhistory=yes, width=600, height=450,top=100,left=350');void(0)"
            aria-label="facebook"
          >
            <FacebookFilled />
          </a>
        </div>
      )}
    </Contact>
  );

  const _renderDefaultSizeFooter = () => (
    <Row gutter={30}>
      <Col
        xs={24}
        sm={24}
        md={columns >= 2 ? 12 : 24}
        lg={colSpan}
        xl={colSpan}
        xxl={colSpan}
      >
        {_renderCompanyDescription()}
      </Col>
      {showHelpfulLink && (
        <Col
          xs={24}
          sm={24}
          md={columns >= 2 ? 12 : 24}
          lg={colSpan}
          xl={colSpan}
          xxl={colSpan}
        >
          {_renderHelpfulLinks()}
        </Col>
      )}
      {showNews && (
        <Col
          xs={24}
          sm={24}
          md={columns >= 2 ? 12 : 24}
          lg={colSpan}
          xl={colSpan}
          xxl={colSpan}
        >
          {_renderNews()}
        </Col>
      )}
      {showContact && (
        <Col
          xs={24}
          sm={24}
          md={columns >= 2 ? 12 : 24}
          lg={colSpan}
          xl={colSpan}
          xxl={colSpan}
        >
          {_renderContact()}
        </Col>
      )}
    </Row>
  );

  const _renderResizedFooter = () => (
    <>
      <Row gutter={30}>{_renderCompanyDescription()}</Row>
      {showHelpfulLink && <Row gutter={30}>{_renderHelpfulLinks()}</Row>}
      {showNews && <Row gutter={30}>{_renderNews()}</Row>}
      {showContact && <Row gutter={30}>{_renderContact()}</Row>}
    </>
  );

  const { showMap } = useSelector((state) => state.carparks);
  const isListingPage = window.location.pathname === CAR_PARK_LIST;

  return (
    <FooterWrapper showMap={showMap && isListingPage} resized={resized}>
      <ContentWrapper>
        {resized ? _renderResizedFooter() : _renderDefaultSizeFooter()}
      </ContentWrapper>
      <CopyrightWrapper>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span style={{ marginBottom: 5 }}>
            {t("home.searchArea.poweredBy")}
          </span>
          <a
            href={`https://www.letspark.com.hk/${
              i18n.language === "en" ? "en-US" : "zh-HK"
            }/contact`}
          >
            <img
              src={require("../../../assets/images/letspark-logo.png")}
              width={100}
            />
          </a>
        </div>
        <div>
          {t("footer.reserved", {
            year: moment().year(),
            name: getMultiLangProperty(merchant, "name", i18n.language),
          })}
        </div>
        <div>{`${t("footer.version")}: 20210902.01`}</div>
      </CopyrightWrapper>
    </FooterWrapper>
  );
};

export default Footer;
