import { bindActionCreators } from "redux";
import { useDispatch } from "react-redux";
import { useMemo } from "react";
import ParkingActions from "./parking/action";
import SiteActions from "./site/action";
import ServiceContentActions from "./servicecontent/action";
import NewsActions from "./news/action";
import CarparksActions from "./carparks/action";
import MerchantActions from "./merchant/action";
import SpacesActions from "./spaces/action";
import VehiclesActions from "./vehicles/action";
import DatesActions from "./dates/action";
import AuthActions from "./auth/action";
import UsersActions from "./users/action";
import AggregatorActions from "./aggregator/action";
import PromotionActions from "./promotion/action";
import BookingsActions from "./bookings/action";
import InvoicesActions from "./invoices/action";
import TransactionsActions from "./transactions/action";
import UploadActions from "./upload/action";
import OCRActions from "./ocr/action";

export const ActionCreators = {
  ParkingActions,
  SiteActions,
  ServiceContentActions,
  NewsActions,
  CarparksActions,
  MerchantActions,
  SpacesActions,
  VehiclesActions,
  DatesActions,
  AuthActions,
  UsersActions,
  AggregatorActions,
  PromotionActions,
  BookingsActions,
  InvoicesActions,
  TransactionsActions,
  UploadActions,
  OCRActions,
};

export function useActions(actions, deps) {
  const dispatch = useDispatch();
  return useMemo(
    () => {
      if (Array.isArray(actions)) {
        return actions.map((a) => bindActionCreators(a, dispatch));
      }
      return bindActionCreators(actions, dispatch);
    },
    deps ? [dispatch, ...deps] : [dispatch]
  );
}

export default ActionCreators;
