import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

export const FooterWrapper = styled.div`
  width: ${({ showMap }) => (showMap ? "60%" : "100%")};
  width: ${({ resized }) => resized && resized};
`;

export const ContentWrapper = styled.div`
  padding: 40px 50px;
`;

export const CopyrightWrapper = styled.div`
  background-color: rgb(235, 235, 235);
  padding: 40px 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  div {
    font-size: 13px;
    margin-bottom: 5px;
    @media only screen and (max-width: 991px) {
      width: 100%;
    }
  }
  @media only screen and (max-width: 991px) {
    flex-direction: column;
  }
`;

export const CompanyDescription = styled.div`
  margin-bottom: 20px;
  h4 {
    margin-top: 10px;
    font-size: 16px;
  }
  p {
    color: #7a7a7a;
    font-weight: 700;
    margin-top: 5px;
    font-size: 13px;
  }
`;

export const HelpfulLinks = styled.div`
  margin-bottom: 20px;
  h4 {
    margin-bottom: 5px;
    font-size: 16px;
  }
  a {
    color: #7a7a7a;
    font-weight: 600;
    position: relative;
    line-height: 15px;
    font-size: 13px;

    ::before {
      content: "";
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 100%;
      height: 1px;
      display: block;
      transition: transform 500ms;
      transform: scale(0, 1);
      background-color: ${themeGet("primary.0", "#ff0000")};
      transform-origin: left;
    }

    &:hover {
      color: ${themeGet("primary.0", "#ff0000")};

      &::before {
        transform: scale(1, 1);
        transform-origin: right;
      }
    }
  }
`;

export const News = styled.div`
  margin-bottom: 20px;
  h4 {
    margin-bottom: 5px;
    font-size: 16px;
  }
  .news-card {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    div {
      p {
        margin-bottom: 0;
        color: ${themeGet("primary.0", "#ff0000")};
        font-size: 13px;
      }
      a {
        font-weight: 700;
        color: #7a7a7a;
        transition: 500ms;
        font-size: 13px;

        &:hover {
          color: ${themeGet("primary.0", "#ff0000")};
          transition: 500ms;
        }
      }
    }
  }
`;

export const Contact = styled.div`
  margin-bottom: 20px;
  h4 {
    margin-bottom: 5px;
    font-size: 16px;
  }
  div {
    font-weight: 700;
    color: #7a7a7a;
    line-height: 25px;
    font-size: 13px;
    a {
      color: #7a7a7a;
      transition: 500ms;
      &:hover {
        color: ${themeGet("primary.0", "#ff0000")};
        transition: 500ms;
      }
      svg {
        font-size: 18px;
        margin-top: 10px;
      }
    }
  }
`;
