import styled from "styled-components";

export const LogoWrapper = styled.div`
  img {
    width: ${({ width }) => (width ? `${width}px` : "190px")};
    max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : "190px")};
    max-height: 40px;
    object-fit: contain;
  }
`;
