import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

export const Wrapper = styled.div`
  width: 90vw;
  background-color: white;
  height: 180px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  border-bottom-right-radius: 10px;
  box-shadow: 0 6px 12px ${themeGet("boxShadow.2", "rgba(0, 0, 0, 0.16)")};
  display: block;

  &.is_show_transition {
    display: none;
    transition: all 1s ease-in;
  }

  .ant-row {
    height: 100%;
  }

  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 200px;
    }
  }

  @media only screen and (max-width: 991px) {
    display: none;
  }
`;

export const SocialLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 20%;
  padding: 0 50px;

  a {
    color: #7a7a7a;
    margin-left: 10px;
    transition: 500ms;
    display: flex;
    align-items: center;

    span {
      margin-left: 5px;
    }

    &:hover,
    &:active,
    &:focus {
      color: ${themeGet("primary.0", "#ff0000")};
      transition: 500ms;
    }

    svg {
      font-size: 18px;
    }
  }
`;

export const NavLink = styled.div`
  background-color: ${themeGet("primary.0", "#ff0000")};
  width: 100%;
  height: 40%;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ numOfChild }) =>
    numOfChild > 3 ? "space-between" : "flex-start"};
  color: white;
  box-shadow: 0 6px 12px ${themeGet("boxShadow.2", "rgba(0, 0, 0, 0.16)")};
  padding: 0 50px;

  a {
    margin-right: ${({ numOfChild }) => (numOfChild > 3 ? 0 : "100px")};
  }

  h3 {
    color: white;

    &:hover {
      text-shadow: 2px 2px 5px #5d5d5d;
    }
  }

  button {
    border: none;
    background: transparent;
    cursor: pointer;
    outline: none;

    svg {
      font-size: 18px;
    }
  }
`;

export const Other = styled.div`
  height: 40%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export const Contact = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  div {
    background-color: ${themeGet("primary.0", "#ff0000")};
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    transition: 500ms;

    svg {
      font-size: 14px;
      color: white;
    }
  }

  a {
    font-weight: 700;
    color: #7a7a7a;
    transition: 500ms;
  }

  &:hover {
    div {
      background-color: #7a7a7a;
      transition: 500ms;
    }

    a {
      color: ${themeGet("primary.0", "#ff0000")};
      transition: 500ms;
    }
  }
`;

export const IconButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    font-size: 18px;
    color: ${themeGet("primary.0", "#ff0000")};
  }

  a {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 700;
    color: #7a7a7a;
    transition: 500ms;

    span {
      margin-left: 10px;
    }

    @media only screen and (max-width: 1250px) {
      span {
        display: none;
      }
    }
  }

  &:hover {
    svg {
      color: #7a7a7a;
      transition: 500ms;
    }

    a {
      color: ${themeGet("primary.0", "#ff0000")};
      transition: 500ms;
    }
  }
`;
